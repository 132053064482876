import React from 'react'
import { Link } from 'react-router-dom'
import './header.css'

function Header() {
  return (
    <div className='header'>
        {/* <div className="headerTittle">
          @Mag Coffee House
          </div> */}
          <Link to={{pathname:`/`}}>
    <img className='topPhoto' src="..\assets\magLogo.jpg" alt="Logo" />
    </Link>
    </div>
  )
}

export default Header