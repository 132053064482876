import React from 'react'
import { Link } from "react-router-dom";
import './product.css'
import { useState, useLayoutEffect, useContext, useEffect } from 'react'

function Product({ item }) {
  return (
    <Link to={`/`} style={{ textDecoration: 'none', color: 'black' }}>
    <div className='product-home-rec'>
            <div className='product-item-header'>
            <img className="product-item-img"src= {item.image ? item.image : '../assets/resimyok.png'} alt="" />
            </div>
            <div className="product-item-lower">
                <p className='product-item-title'>{item.productName}</p>
                {/* {item.productDescription && (
                <p className='product-item-description'>{item.productDescription}</p>
                )} */}
                <p className='product-item-description'>{item.productDescription}</p>
                <div className="product-item-alt-kisim">
                  {item.serviceTime && (
                    <div className="product-item-time-div">
                      <img className='product-item-time-icon' src="../assets/clock.svg" alt=""/>
                      <p className='product-item-time-text'>{item.serviceTime} Dakikada Masanda</p>
                    </div>
                  )}
                  <p className='product-item-price'>₺ {item.price}</p>
                </div>
            </div>
    </div>
    </Link>
  )
}

export default Product