import React from 'react'
import { Link } from "react-router-dom";
import './categoryProduct.css'
import { useState, useLayoutEffect, useContext, useEffect } from 'react'

function CategoryProduct({ item }) {
  return (
    // product menusu yapilacak sonra...
    <Link to={`/`} style={{ textDecoration: 'none', color: 'white'}}>
    <div className='categoryProduct'>
            <div className='category-item-header'>
                <img className="category-item-img"src= {item.image ? item.image : 'https://cdn.yemek.com/mnresize/940/940/uploads/2016/08/sucuklu-kasarli-tost.jpg'} alt="" />
            </div>
            <div className="category-item-lower">
                <p className='category-item-title'>{item.productName}</p>
                {item.productDescription && (
                <p className='category-item-description'>{item.productDescription}</p>
                )}
                {!item.productDescription && (
                <p className='category-item-description'>{item.productDescription}</p>
                )}
                <p className='category-item-price'>₺{item.price}</p>
            </div>
    </div>
    </Link>
  )
}

export default CategoryProduct