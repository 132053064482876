import axios from 'axios';
import { useState, useLayoutEffect, useContext, useEffect } from 'react'
import {BrowserRouter as Router, Switch,Route, Redirect} from "react-router-dom";
import Login from "./pages/login/Login";
import Home from "./pages/home/Home";
import VendorPanel from "./pages/vendorPanel/VendorPanel";

import AppLevelContext from "./context/AppLevelContext";
import Footer from './components/footer/Footer';
import Category from './pages/category/Category';
import Product from './pages/product/Product';

axios.defaults.baseURL = 'https://api.mag.coffee';

function App() {
  const { menuList, setMenuList } = useContext(AppLevelContext)
  
  useEffect(async () => {
        await axios("/menu/category?filter[topCategory][$exists]=false&sort[categoryName]=1")
            .then((resp) => {
              setMenuList(resp.data)
              console.log(resp.data)
            })
            .catch((e) => console.log(e))
    }, []);

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Home/>
        </Route>
        <Route path="/category">
          <Category/>
        </Route>
        <Route path="/product">
          <Product/>
        </Route>
      </Switch>
    </Router>
  );
  
}

export default App;
