import axios from "axios";
import "./categoryRenderer.css";
import { useEffect, useState, useContext } from "react"
import AppLevelContext from '../../context/AppLevelContext';
import CategoryWidget from "../categoryWidget/CategoryWidget";



function CategoryRenderer() {

    const { menuList } = useContext(AppLevelContext);
    return (
        <div className='categoryRenderer'>
            {
                menuList.map((category) => (
                    <CategoryWidget category={category._id} categoryData={category} icon={category.image} title={category.categoryName}/>
                ))
            }

                
        </div>
    )
}
export default CategoryRenderer