import axios from 'axios';
import React from 'react'
import './categoryItem.css'
import { useState, useLayoutEffect, useContext, useEffect } from 'react'
import AppLevelContext from '../../context/AppLevelContext';
import CategoryProduct from '../categoryProduct/CategoryProduct';

function convertToQueryString(obj, prefix = '') {
  const queryString = Object.entries(obj)
    .map(([key, value]) => {
      const paramName = prefix ? `${prefix}[${key}]` : key;
      if (Array.isArray(value) || typeof value === 'object') {
        return convertToQueryString(value, paramName);
      } else {
        return `${paramName}=${encodeURIComponent(value)}`;
      }
    })
    .join('&');

  return queryString;
}

const seachOptions = {
  populate: [
    {path: 'products'},
    {path: 'subCategories', options:{sort:{'categoryName':1}}, populate:'products'}
  ],
}

const searchQuery = convertToQueryString(seachOptions);



function CategoryItem({ categoryId }) {
    const [getProducts, setProducts,] = useState([]);
    const category = getProducts;
    const categoryUrl = `/menu/category/${categoryId}?${searchQuery}`;
    useEffect(() => {
        const getProducts = async () => {
          try {
            const res = await axios.get(categoryUrl);;	
            setProducts(res.data);
          } catch (err) {
            console.log(err);
          }
        };
        getProducts();
      }, []);
  return (
    <div id={category.categoryName}>
      
      <div className='category-item-name'>{category.categoryName}</div>
      {/* {category.categoryDescription && (
        <p className='sub_message'>{category.categoryDescription}</p>
        // <img className='category-img' src={category.categoryDescription} alt="" />
      )} */}

        <div className="categoryFirst">
            {category.products && category.products.map(item =>(
              <CategoryProduct item={item} key={item._id} />
           ))}
        </div>
      

      {/* {category.subCategories && category.subCategories.map(sub_category => (
        <div className='sub_category' key={sub_category.id}>
          <div className='sub_category_name'>{sub_category.name}</div>
          {sub_category.items && sub_category.items.map(item => (
            <CategoryProduct item={item} key={item.id} />
          ))}
        </div>
      ))} */}
      {
        category.subCategories && category.subCategories.map(subCategory =>(
          <div className='sub_category' key={subCategory._id}>
            <div className='sub_category_name'>{subCategory.categoryName}</div>
          {subCategory.products && subCategory.products.map(product => (
            <CategoryProduct item={product} key={product._id} />
          ))}
        </div>
        ))
      }
    </div>
  )
}

export default CategoryItem