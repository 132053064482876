import { Link } from "react-router-dom";
import "./categoryWidget.css";
import { useState, useLayoutEffect, useContext, useEffect } from 'react'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

function CategoryWidget(props) {
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  function formatCategoryName(name) {
    // İsimdeki boşlukları kaldırın ve küçük harfe dönüştürün
    const formattedName = name.trim().toLowerCase();
  
    // İsimdeki boşlukları "-" ile değiştirin
    const formattedNameWithDash = formattedName.replace(/\s+/g, '-');
  
    return formattedNameWithDash;
  }
  const formatedCategoryName = formatCategoryName(props.title);
  const banner = props.categoryData.image
  console.log(banner)
  return (
    <div className='categoryWidget'>
      <Link to={{pathname:`/category/${formatedCategoryName}`,
      state:props.category
    }}
      style={{ textDecoration: 'none', color: isHovering ? "#F7C048" : "black", WebkitTextFillColor: isHovering ? "white" : "white"}}  onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <img src={banner ? banner : '../assets/resimyok.png'} alt="" className="categoryImg" />
        <span className="categoryTitle">{props.title}</span>
        {/* <div className="categoryAngle" style={{backgroundColor: isHovering ? 'white' : "white"}} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <KeyboardArrowRightIcon style={{color: isHovering ? "black":'black'}} className={'categoryAngleIcon'} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        </KeyboardArrowRightIcon>
        </div> */}
    </Link>

    </div>
  )
}

export default CategoryWidget 