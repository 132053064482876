import axios from 'axios';
import React from 'react'
import './productItem.css'
import { useState, useLayoutEffect, useContext, useEffect } from 'react'
import AppLevelContext from '../../context/AppLevelContext';
import Product from '../product/Product';


function ProductItem() {
    const [getProducts, setProducts] = useState([]);
    useEffect(() => {
        const getProducts = async () => {
          try {
            const res = await axios.get("/menu/product?filter[isFavorite]=true");
            setProducts(res.data);
            console.log(res.data)
          } catch (err) {
            console.log(err);
          }
        };
        getProducts();
      }, []);
      const [getRecomended, setRecomended] = useState([]);
      useEffect(()=>{
        const getRecomended = async() => {
          try {
            const res = await axios.get("/menu/product?filter[isActive]=true");
            setRecomended(res.data);
            console.log(res.data)
          } catch (err) {
            console.log(err)
          }
        }
        getRecomended();
      }, [])
  return (
    <div className='recommendedProductDiv'>
      <div className='categoryTittle2'>
        Kampanyalar
      </div>
      <div className='recommendedProduct'>
        {
            getProducts.map((item) => (
                <Product item={item}/>
             ))
        }
      </div>
      <div className='categoryTittle2'>
        Önerilenler
      </div>
      <div className='recommendedProduct'>
        {/* {
            getRecomended.map((item) => (
                <Product item={item}/>
             ))
        } */}
      </div>
    </div>
  )
}

export default ProductItem