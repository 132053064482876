import React from 'react'
import CategoryItem from '../../components/categoryItem/CategoryItem'
import { useLocation } from 'react-router-dom'
import Footer from '../../components/footer/Footer'
import Header from '../../components/header/Header';
import "./category.css";



export default function Category() {
  const location = useLocation();
  const categoryId = location.state;
  console.log(categoryId);
  // const categoryId = location.pathname.split('/')[2];
  return (
    <div className='category'>
        <Header/>
      <div className="category-items">
        <CategoryItem categoryId={categoryId}/>
      </div>
    </div>
  )
}
