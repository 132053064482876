import CategoryList from "../../components/categoryList/CategoryList";
import CategoryRenderer from "../../components/categoryRenderer/CategoryRenderer";
import Header from "../../components/header/Header";
import Product from "../../components/product/Product";
import Topbar from "../../components/topbar/Topbar";
import AppLevelContext from "../../context/AppLevelContext";
import { useState, useLayoutEffect, useContext, useEffect } from 'react'
import ProductItem from "../../components/productItem/ProductItem";
import "./home.css";
import Footer from "../../components/footer/Footer";


export default function Home() {
  return (
    <div className="home">
      <div className="top-home">
        <Header/>
      </div>
        <div className="bottom-home">
        <CategoryList/>
        <ProductItem/>
        </div>
    </div>
  )
}
