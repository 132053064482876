import React from 'react'
import './categoryList.css'
import CategoryRenderer from '../categoryRenderer/CategoryRenderer'

function CategoryList() {
  return (
    <div className='categoryList'>
        <div className="categoryTittle">
            Menu
        </div>
        <CategoryRenderer/>
    </div>
  )
}

export default CategoryList